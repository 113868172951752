import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO />
    <br />
    <p>
      howdy,
    </p>
    <p>
      <span href="#" rel="noopener" class="name">Max Samarripa</span> is a U.S. (CO) based software developer and can be:
    </p>
    <p>
      found on
      <br />
      <a href="https://gitlab.com/max.samarripa" target="_blank" rel="noopener noreferrer">gitlab</a>
      <br />
      <a href="https://www.linkedin.com/in/max-samarripa-465028126/" target="_blank" rel="noopener noreferrer">linkedin</a>.
    </p>
    <p>
      reached via <br />
      <a href="mailto:max.samarripa@pm.me">email</a>
    </p>
    <p>
      :~)
    </p>
  </Layout>
)

export default IndexPage
